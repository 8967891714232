import './main.scss'
import { openPopup, closePopup, urlJoin } from '../../utils'

document.addEventListener('DOMContentLoaded', () => {
	setTimeout(() => calcLeash(), 100)

	formSubmit()

	popupBackdrops()
	demoButtons()

	cookies()
})

window.addEventListener('resize', () => {
	calcLeash()
})

function calcLeash() {
	const leashArchor = document.getElementById('leash-archor')
	const leash = document.getElementById('leash')
	const dog = document.getElementById('dog')

	const dogHeight = dog.getBoundingClientRect().height
	const dogViewBoxHeight = dog.viewBox.baseVal.height || dogHeight
	const dogScale = dogHeight / dogViewBoxHeight

	const leashArchorX = leashArchor.getBoundingClientRect().left
	const offsetY = dogHeight - Number(leashArchor.getAttribute('cy')) * dogScale

	const windowWidth = window.innerWidth
	const width = windowWidth - leashArchorX

	leash.style.left = leashArchorX
	leash.style.bottom = offsetY
	leash.setAttribute('width', width)
}

function formSubmit() {
	document.querySelectorAll('form').forEach((form) => {
		form.addEventListener('submit', (e) => {
			e.preventDefault()

			const full_name = form.querySelector('input[name="first_name"]').value
			const email = form.querySelector('input[name="email"]').value
			const users_count = form.querySelector('input[name="employees"]').value
			// const privacy = form.querySelector('input[name="privacy"]').checked

			const data = {
				full_name,
				email,
				users_count,
			}

			const url = urlJoin([API_URL, '/v2/forms/demo']) + '/'

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})
				.then(() => {
					// TODO check response
					closePopup('demo-popup') // force close popup
					clearForm(form)
					openPopup('ok-popup')
				})
				.catch(() => {
					alert('Something went wrong. Please try again later.')
				})
		})
	})
}

function clearForm(form) {
	form.querySelector('input[name="first_name"]').value = ''
	form.querySelector('input[name="email"]').value = ''
	form.querySelector('input[name="employees"]').value = ''
	form.querySelector('input[name="privacy"]').checked = false
}

function popupBackdrops() {
	document.querySelectorAll('.popup').forEach((popup) => {
		const backdrop = popup.querySelector('.popup-backdrop')

		const close_func = () => {
			closePopup(popup.id)
		}

		backdrop.addEventListener('click', close_func)
		popup
			.querySelectorAll('.popup-close')
			.forEach((close) => close.addEventListener('click', close_func))
	})
}

function demoButtons() {
	document.querySelectorAll('.demo-button').forEach((button) => {
		button.addEventListener('click', () => {
			openPopup('demo-popup')
		})
	})
}

function cookies() {
	const button = document.querySelector('#cookies-button')
	const banner = document.querySelector('#cookies-banner')

	const accepted = localStorage.getItem('cookies-accepted') === 'true'

	if (!accepted) {
		banner.style.display = 'flex'
	}

	button.addEventListener('click', () => {
		banner.style.display = 'none'
		localStorage.setItem('cookies-accepted', 'true')
	})
}
