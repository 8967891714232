//////////////////////////////////////////////////////////////

export function urlJoin(parts) {
	return parts
		.filter((p) => p !== undefined && p !== null)
		.map((p) => (p ? p.toString().replace(/^\/+|\/+$/gm, '') : ''))
		.filter((p) => p.length > 0)
		.join('/')
}

export function openPopup(id) {
	const popup = document.querySelector(`#${id}`)
	if (!popup) {
		console.error(`Unable to find popup with id ${id}`)
		return
	}
	popup.style.display = 'flex'
}

export function closePopup(id) {
	const popup = document.querySelector(`#${id}`)
	if (!popup) {
		console.error(`Unable to find popup with id ${id}`)
		return
	}
	popup.style.display = 'none'
}
